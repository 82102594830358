













































































import { isEqual } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins } from 'vue-property-decorator'

import AppendMasterToGroupModal from '@/components/modals/masterGroups/AppendMasterToGroupModal.vue'
import ConflictGroupsModal from '@/components/modals/masterGroups/ConflictGroupsModal.vue'
import CourseSearch from '@/components/_uikit/search/CourseSearch.vue'
import ManagerGroupsModule from '@/store/modules/manager/groups'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import CourseGroupMonthChainMixin from '@/mixins/CourseGroupMonthChainMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import {
  IMasterGroupsFilter,
  IMasterGroupsFilterType,
  MasterAlreadyInGroupResource,
  NameValueChildrenResource,
  NameValueChildrenTypeResource,
  UserShortResource,
} from '@/store/types'
import { tableFooterOptions } from '@/utils/constants'
import { GET_DEFAULT_PAGINATOR } from '@/store'

@Component({
  components: {
    AppendMasterToGroupModal,
    ConflictGroupsModal,
    CourseSearch,
    Select,
    TableFooter,
    TextInput,
    UserSocialCard,
  },
})
export default class UnallocatedMasterTable extends Mixins(CourseGroupMonthChainMixin, NotifyMixin, PermissionsMixin) {
  private showAppendToGroupModal = false
  private showConflictGroupsModal = false
  private selectedMasters: UserShortResource[] = []
  private footerOptions = tableFooterOptions

  private conflictMasters: MasterAlreadyInGroupResource[] = []

  private get filter () {
    return ManagerGroupsModule.unallocatedMastersFilter
  }

  private set filter (filter: IMasterGroupsFilter) {
    if (!isEqual(filter, this.filter) || this.masters.length === 0) {
      ManagerGroupsModule.setUnallocatedMastersFilter(filter)
      this.$emit('filter', filter)
      this.fetchUnallocatedMasters()
    }
  }

  private get headers () {
    return [
      { sortable: false, text: 'Пользователь', value: 'user' },
      { sortable: false, text: 'Почта', value: 'email' },
      { sortable: false, text: 'Город', value: 'city' },
    ]
  }

  private get masters () {
    return ManagerGroupsModule.unallocatedMasters.data
  }

  private get pagination () {
    return ManagerGroupsModule.unallocatedMasters.meta
  }

  private get groupID() {
    const findGroup = this.groupTypes.find((group: NameValueChildrenResource | NameValueChildrenTypeResource) => (group as NameValueChildrenTypeResource).type === this.filter.groupType)

    if (findGroup) {
      return findGroup.value
    }

    return -1
  }

  private get unallocatedCourse() {
    return ManagerGroupsModule.unallocatedCourses.find(course => course.value === this.filter.courseId)?.name || ''
  }

  @Bind
  @Debounce(300)
  private fetchUnallocatedMasters(resetSelectedMasters = false, masters?: MasterAlreadyInGroupResource[]) {
    if (masters && masters.length) {
      this.conflictMasters = masters
      this.showConflictGroupsModal = true
    }
    if (!this.filter.courseId || !this.filter.groupType) {
      this.selectedMasters = []
      ManagerGroupsModule.setUnallocatedMasters(GET_DEFAULT_PAGINATOR())
    } else {
      ManagerGroupsModule.fetchUnallocatedMasters()
        .then(() => {
          if (resetSelectedMasters)
            this.selectedMasters = []
        })
        .catch(this.notifyError)
    }
  }

  private onCourseChange () {
    this.filter.groupType = undefined
    this.selectedMasters = []
  }

  private handleFilter (field: IMasterGroupsFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
    this.selectedMasters = []
  }

  private handleCloseConflictGroupModal(count: number) {
    this.showConflictGroupsModal = false
    this.conflictMasters = []
    if (count)
      this.fetchUnallocatedMasters(true)
  }
}
