





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// components
import ConflictMaster from '@/components/ConflictMaster.vue'
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
// store
import { MasterAlreadyInGroupResource } from '@/store/types'

@Component({
  components: {
    ConflictMaster,
    Dialog,
    ModalWrapper,
  },
})
export default class ConflictGroupsModal extends Vue {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ required: true })
  private courseID!: number

  @Prop({ required: true })
  private groupID!: number

  @Prop({ required: true })
  private masters!: MasterAlreadyInGroupResource[]

  @Prop({ default: '' })
  private courseTitle!: string

  // Количество удаленных мастеров из нераспределенных
  // Если больше 0, то при закрытии модалки нужно перезапросить нераспределенных мастеров,
  // чтобы исключить тех, кого оттуда удалили
  private deleteMastersCount = 0

  private handleClose () {
    this.$emit('close', this.deleteMastersCount)
    this.deleteMastersCount = 0
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    if (value)
      this.deleteMastersCount = 0
  }
}
