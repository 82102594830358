




import { MetaInfo } from 'vue-meta'
import { Vue, Component } from 'vue-property-decorator'

import UnallocatedMasterTable from '@/components/tables/UnallocatedMasterTable.vue'
import { IMasterGroupsFilter } from '@/store/types'

@Component({
  components: { UnallocatedMasterTable },
})
export default class Masters extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Нераспределенные мастера',
    }
  }

  private handleFilter(filter: IMasterGroupsFilter) {
    this.$emit('filter', { allocated: false, payload: { ...filter } })
  }
}
