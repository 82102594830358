





























import { Component, Prop } from 'vue-property-decorator'

// components
import Tag from '@/components/_uikit/Tag.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import { MasterAlreadyInGroupResource } from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerGroupsModule from '@/store/modules/manager/groups'

@Component({
  components: {
    Tag,
    UserSocialCard,
  },
})
export default class ConflictMaster extends NotifyMixin {
  @Prop({ required: true })
  private courseID!: number

  @Prop({ required: true })
  private groupID!: number

  @Prop({ required: true })
  private master!: MasterAlreadyInGroupResource

  private get groupTypes() {
    const types: { [key: string]: string } = {}
    DictionaryModule.groupTypes.map(type => types[type.value] = type.name)
    return types
  }

  private isLoading = false
  private state: 'cancel' | 'delete' | null = null

  private fetchUnallocatedReject() {
    this.isLoading = true
    ManagerGroupsModule.fetchUnallocatedReject({
      courseGroupId: this.groupID,
      courseId: this.courseID,
      userId: this.master.userId,
    })
      .then(() => {
        this.state = 'delete'
        this.$emit('deleteMaster')
      })
      .catch(this.notifyError)
      .finally(() => {
        this.isLoading = false
      })
  }
}
