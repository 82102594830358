






























import { Bind, Debounce } from 'lodash-decorators'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import Dialog from '@/components/modals/Dialog.vue'
import AvailableMasterGroupsSearch from '@/components/_uikit/search/AvailableMasterGroupsSearch.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import UsersSingleSearch from '@/components/_uikit/search/UsersSingleSearch.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerGroupsModule from '@/store/modules/manager/groups'
import {
  MasterAlreadyInGroupResource,
  MasterGroupTransferMastersRequest,
  NameValueResource,
  UserShortResource,
} from '@/store/types'

@Component({
  components: {
    AvailableMasterGroupsSearch,
    Dialog,
    ModalWrapper,
    Select,
    UsersSingleSearch,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class AppendMasterToGroupModal extends Mixins(NotifyMixin) {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ required: true })
  private masters!: UserShortResource[]

  @Prop({ required: true })
  private courseID!: number

  @Prop({ required: true })
  private groupID!: number

  private availableGroups: NameValueResource[] = []
  private form: MasterGroupTransferMastersRequest = {
    masterGroupId: 0,
    masterIds: [],
  }

  private get masterIds () {
    return this.masters.map((master: UserShortResource) => master.id)
  }

  private resetForm () {
    this.form = {
      masterGroupId: 0,
      masterIds: this.masterIds,
    }
  }

  @Debounce(300)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          ManagerGroupsModule.transferMastersToGroup(this.form)
            .then((response: MasterAlreadyInGroupResource[]) => {
              if (!response.length)
                this.notifySuccess('Мастера добавлены в группы')
              this.$emit('success', response)
              this.$emit('close')
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private handleClose () {
    this.$emit('close')
  }

  @Watch('visible')
  private watchVisible (value: boolean) {
    if (value) {
      this.resetForm()
    }
  }
}
