























import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import NotifyMixin from '@/mixins/NotifyMixin'
import { NameValueResource } from '@/store/types'
import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import ManagerGroupsModule from '@/store/modules/manager/groups'

@Component({
  inheritAttrs: false,
})
export default class AvailableMasterGroupsSearch extends Mixins(DetectSafariMixin, NotifyMixin) {
  @Prop({ required: true })
  private value!: number

  @Prop({ default: () => ([]) })
  private masterIds!: number[]

  @Prop({ default: false })
  private self!: boolean

  @Prop( { default: false })
  private returnObject!: boolean

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({ required: true })
  private courseID!: number

  @Prop({ required: true })
  private groupID!: number

  private innerValue = 0
  private search = ''
  private isLoading = false
  private masterGroups: NameValueResource[] = []

  private mounted () {
    this.fetchAllocateMasters()
    if (this.value) {
      this.innerValue = this.value
    }
  }

  private handleSelect (value: number) {
    this.innerValue = value

    if (!this.returnObject) {
      this.$emit('update:value', value)
    } else {
      let masterGroup = this.masterGroups.find((item: NameValueResource) => item.value === value)
      this.$emit('update:value', value)
      this.$emit('input', masterGroup)
    }
  }

  @Bind
  @Debounce(500)
  private fetchAllocateMasters() {
    if (this.isLoading) return

    this.isLoading = true

    ManagerGroupsModule.fetchAllocateMasters({
      courseId: this.courseID,
      groupId: this.groupID,
    })
      .then((response: NameValueResource[]) => {
        this.masterGroups = response
      })
      .catch((err) => {
        this.notifyError(err)
      })
      .finally(() => (this.isLoading = false))
  }

  @Watch('masterIds')
  private watchMasterIds () {
    this.search = ''
    this.masterGroups = []
    this.innerValue = 0
  }
}
